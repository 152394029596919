import React from 'react';
import PropTypes from 'prop-types';
import { Route} from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, auth, ...rest }) => {
    return <Route {...rest} render={props => (<Component {...props}/>)}/>;
};

PrivateRoute.propTypes = {
    component: PropTypes.object.isRequired,
    location: PropTypes.object,
    auth: PropTypes.object.isRequired,
};

// Retrieve data from Store as props
function mapStateToProps(store) {
    return {
        auth: store.auth,
    };
}

export default connect(mapStateToProps)(PrivateRoute);
