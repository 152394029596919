import {connect} from 'react-redux'
import {reducer} from '../../aws-cognito-redux-saga'
import MenuLeftComponent from './MenuLeft'
const mapStateToProps = state => {
    return {
        auth: state.auth,
        loading: true,
        person: null
    }
}
const mapDispatchToProps = dispatch => {
    return {
        signedIn: () => {
            dispatch(reducer.signedIn())
        },
        signOut: () => {
            dispatch(reducer.signOut())
        },
        signIn: (username, password) => {
            dispatch(reducer.signIn(username, password))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MenuLeftComponent)
