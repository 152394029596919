export const SecToTime = (time) => {
    let date = new Date(null);
    date.setSeconds(parseFloat(time)); // specify value for SECONDS here

    return date.toISOString().substr(11, 8);
};

export const TimeToSec = (time) => {
    /*
    time: HH:MM:SS
     */
    if (time) {
        let timeSplit = time.split(':'); // split it at the colons
        return (+timeSplit[0]) * 60 * 60 + (+timeSplit[1]) * 60 + (+timeSplit[2]);
    } else {
        return false;
    }
};

export const StringToHex = (str) => {
    // converting string into buffer
    let bufStr = Buffer.from(str, 'utf8');

    // with buffer, you can convert it into hex with following code
    return bufStr.toString('hex');
};

export const TitleCase = (str) => {
    return str.replace(/\w\S*/g, function (str) {
        return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
    });
};

export const FormatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const Truncate = (input, length) => {
    length = length ? length : 10;
    return input.length > length ? `${input.substring(0, length)}...` : input
};

export const FormatPhone = (phone, intl = true) => {
    let formattedPhoneNumber
    if (intl) {
        formattedPhoneNumber = (str) => {
            //Filter only numbers from the input
            let cleaned = ('' + str).replace(/\D/g, '');
            //Check if the input is of correct
            let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                //Remove the matched extension code
                //Change this to format for any country code.
                // let intlCode = (match[1] ? '+1 ' : '')
                let intlCode = '+1';
                return [intlCode, match[2], match[3], match[4]].join('');
            }
        }
    } else {
        formattedPhoneNumber = (str) => {
            //Filter only numbers from the input
            let cleaned = ('' + str).replace(/\D/g, '');
            //Check if the input is of correct length
            let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                return `${match[1]}-${match[2]}-${match[3]}`;
            };
        };
    }
    return formattedPhoneNumber(phone);
}
