import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Header from "../components/Header";
import MenuLeft from "../components/Menu/MenuLeftContainer";
import Footer from "../components/Footer/Footer";
import {Authenticate as ApiAuth, GetRecord} from "../utils/Api";
import AWS from "aws-sdk";
import {StringToHex} from "../utils/Conversion";

const displayName = 'Private Layout';
const S3UrlExpireSeconds = 3600 * 24;

const propTypes = {
    children: PropTypes.node.isRequired,
    auth: PropTypes.object
};

const s3 = new AWS.S3({
    region: 'ca-central-1',
    credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    }
});

const LeftMenuState = (defaultValue, key) => {
    const [value, setValue] = useState(() => {
        if (window.innerWidth < 768) {
            return 'retract';
        } else {
            const leftMenuStateValue = window.localStorage.getItem(key);
            return leftMenuStateValue !== null
                ? JSON.parse(leftMenuStateValue)
                : defaultValue;
        }
    });
    useEffect(() => {
        window.localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);
    return [value, setValue];
}

export const ToggleLeftMenu = (action) => {
    let leftMenuWidthExpanded;
    if (window.innerWidth > 767) {
        leftMenuWidthExpanded = "247";
    } else {
        leftMenuWidthExpanded = window.innerWidth;
    }

    let leftMenuWidth;
    if (window.innerWidth > 767) {
        leftMenuWidth = "80";
    } else {
        leftMenuWidth = 0;
    }

    if (action === 'retract') {
        // Hide
        if (document.getElementById("menuLeft")) {
            document.getElementById("menuLeft").style.width = leftMenuWidth + "px";
            document.getElementById("menuLeftInner").style.width = leftMenuWidth + "px";
        }
        if (document.getElementById("userSummary"))
            document.getElementById("userSummary").style.display = "none";
        if (document.getElementById("userIconLeft"))
            document.getElementById("userIconLeft").style.display = "block";
        if (document.getElementById("main"))
            document.getElementById("main").style.width = "calc(100% - " + leftMenuWidth + "px)";
    } else if (action === 'expand') {
        // Expand
        if (document.getElementById("menuLeft")) {
            document.getElementById("menuLeft").style.width = leftMenuWidthExpanded + "px";
            document.getElementById("menuLeftInner").style.width = leftMenuWidthExpanded + "px";
        }
        if (document.getElementById("userSummary"))
            document.getElementById("userSummary").style.display = "block";
        if (document.getElementById("userIconLeft"))
            document.getElementById("userIconLeft").style.display = "none";
        if (document.getElementById("main")) {
            if (leftMenuWidth > 0)
                document.getElementById("main").style.width = "calc(100% - " + leftMenuWidthExpanded + "px)";
        }
    }
    if (document.getElementById("menuLeft") && document.getElementById("main")) {
        document.getElementById("main").style.paddingLeft = document.getElementById("menuLeft").style.width;
    }
}

function PrivateLayout({children, auth}) {
    const [userInfo, setUserInfo] = useState({})
    const [activeURL, setActiveURL] = useState(null);
    const [
        currentLeftMenuState,
        SetCurrentLeftMenuState
    ] = LeftMenuState(window.innerWidth < 768 ? 'retract' : 'expand', "currentLeftMenuState");
    useEffect(() => {
        setActiveURL(window.location.pathname);
    }, []);
    useEffect(() => {
        if (auth.info && auth.info.username) {
            const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null;
            if (userData === null) {
                let userApiURI = process.env.REACT_APP_AV_API_URI + "users/" + StringToHex(auth.info.username) + "/";

                // API - Get API TOKENS
                ApiAuth().then((data) => {
                    GetRecord(data['access'], userApiURI).then((resp) => {
                        // Get URL for user image
                        if (resp.image) {
                            const imageURL = 'static/' + resp.image;
                            const thumbURL = s3.getSignedUrl('getObject', {
                                Bucket: process.env.REACT_APP_AWS_S3_BUCKET_VIDEO_USERS,
                                Key: imageURL,
                                Expires: S3UrlExpireSeconds
                            });
                            resp.image = thumbURL;
                        } else {
                            resp.image = false;
                        }
                        localStorage.setItem('userData', JSON.stringify(resp));
                        setUserInfo(resp);
                    });
                });
            } else {
                setUserInfo(userData);
            }
        }
    }, [auth]);
    if (auth.isSignedIn === 'AUTH_FAIL') {
        window.location.href = 'signin';
    } else {
        return (
            <div>
                <main className="fragment">
                    <div>
                        <Header Landing={false} userData={userInfo}
                                currentLeftMenuState={currentLeftMenuState}
                                ToggleLeftMenu={ToggleLeftMenu}
                                SetCurrentLeftMenuState={SetCurrentLeftMenuState}
                        />
                        <div className="row w-100 d-flex align-items-center justify-items-center">
                            <div className={'w-auto'}>
                                <MenuLeft username={auth.info.username} userData={userInfo} setUserInfo={setUserInfo}
                                          activeURL={activeURL} setActiveURL={setActiveURL}
                                          S3UrlExpireSeconds={S3UrlExpireSeconds}
                                          currentLeftMenuState={currentLeftMenuState}
                                          ToggleLeftMenu={ToggleLeftMenu} />
                            </div>
                            {children}
                        </div>
                        <Footer username={auth.info.username}/>
                    </div>
                </main>
            </div>
        )
    }
}

PrivateLayout.displayName = displayName;
PrivateLayout.propTypes = propTypes;
export default PrivateLayout;
