import React from "react";
import {withRouter} from "react-router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileAlt, faVideo} from "@fortawesome/free-solid-svg-icons";
import Moment from "moment";
import {Authenticate as ApiAuth, GetRecord} from "../../utils/Api";
import {NoImgVideo} from "../../Assets/js/image";
import Autosuggest from "react-autosuggest";
import PropTypes from "prop-types";

const displayName = 'Autosuggest Search';
const propTypes = {
    isSignedIn: PropTypes.string,
    auth: PropTypes.object,
    accountId: PropTypes.string,
};
class AutosuggestSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            suggestions: []
        };
    };

    // When suggestion is clicked, Autosuggest needs to populate the input
    // based on the clicked suggestion
    getSuggestionValue = suggestion => suggestion['team_home'] && suggestion['team_visitor'] ? suggestion['team_home'].name + ' at ' + suggestion['team_visitor'].name : null;
    // const getSuggestionValue = suggestion => '';

    // Render suggestions
    renderSuggestion = suggestion => (
        <div onClick={(e) => {
            window.location.href = encodeURI(suggestion.video ? `/watch/${suggestion.accountId}/${suggestion.video}` : `/scoresheet/${suggestion.scoresheet}`);
            // this.props.history.push(encodeURI(suggestion.video ? `/watch/${suggestion.accountId}/${suggestion.video}` : `/scoresheet/${suggestion.scoresheet}`))
        }}
             style={{color: 'black', fontSize: 12}}>
            {suggestion['team_home'] && suggestion['team_visitor'] ? (
                <strong>{suggestion['team_home'].name} at {suggestion['team_visitor'].name}</strong>
            ) : suggestion['eventName'] ? (
                <strong>{suggestion['eventName']}</strong>
            ) : null}
            {suggestion.video ? (
                <FontAwesomeIcon icon={faVideo} className={'float-right'}/>
            ) : (
                <FontAwesomeIcon icon={faFileAlt} className={'float-right'}/>
            )}
            <br/>
            {suggestion.event_date ? Moment(suggestion.event_date).format("dddd MMM Do, YYYY") : null}
        </div>
    );
    onChange = (event, {newValue}) => {
        this.setState((prevState, props) => {
            return {value: newValue};
        });
    };

    renderSuggestionsContainer({containerProps, children, query}) {
        return (
            <div {...containerProps}>
                {children}
                <div>
                    Press Enter to search <strong>{query}</strong>
                </div>
            </div>
        );
    };

    onSuggestionsFetchRequested = ({value}) => {
        const {accountId} = this.props;
        const eventApiURI = process.env.REACT_APP_AV_API_URI + "event/e/";
        let suggest
        if (accountId && value && value) {
            let url = `${eventApiURI}?account=${accountId}&search=${value}`;
            ApiAuth().then((data) => {
                GetRecord(data['access'], url).then((resp) => {
                    let results = resp.results;
                    for (let key in results) {
                        results[key].accountId = accountId;
                        if (!results[key].image) {
                            results[key].image = NoImgVideo;
                        }
                    }
                    suggest = results;
                    this.setState((prevState, props) => {
                        return {suggestions: suggest};
                    });
                });
            });
        }
    };
    onSuggestionsClearRequested = () => {
        this.setState((prevState, props) => {
            return {suggestions: []};
        });
    };
    handleSuggestionKeyDown = (e, value) => {
        if (e.key === "Enter") {
            window.location.href = "/search/" + value;
        }
    };

    render() {
        const {value, suggestions} = this.state;

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: '',
            value,
            onChange: this.onChange,
            onKeyDown: (e) => {
                this.handleSuggestionKeyDown(e, value)
            }
        };

        // Finally, render it!
        return (
            <div>
                <Autosuggest
                    id={'header-search-results'}
                    suggestions={suggestions}
                    accountId={this.props.accountId}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    inputProps={inputProps}
                />
            </div>
        );
    }
}
AutosuggestSearch.displayName = displayName;
AutosuggestSearch.propTypes = propTypes;
export default withRouter(AutosuggestSearch);
