import {createStore, applyMiddleware, compose} from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from "../reducers";
import sagas from "../sagas";

const sagaMiddleware = createSagaMiddleware();

let composeEnhancers = compose;

if (process.env.NODE_ENV === 'development') {
    const composeWithDevToolsExtension =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    if (typeof composeWithDevToolsExtension === 'function') {
        composeEnhancers = composeWithDevToolsExtension
    }
}

const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(sagas);


export default store;
