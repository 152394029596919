import {useEffect} from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    getUser: PropTypes.func
}
const Auth = ({getUser}) => {
    useEffect(() => {
        getUser();
    }, [getUser]);
    return true;
}
Auth.propTypes = propTypes;
export default Auth
