import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PrivateLayout from './Private';
import PublicLayout from './Public';
import {state} from '../aws-cognito-redux-saga';


function Layout(props) {
    const {children, auth} = props;
    if (auth.isSignedIn === state.AUTH_SUCCESS) {
        return <PrivateLayout {...props}>{children}</PrivateLayout>;
    }

    return <PublicLayout {...props}>{children}</PublicLayout>;
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
