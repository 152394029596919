import React, {Suspense} from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import Loader from "../components/Loader/Loader";
import routes from './Routes';
import PrivateRoute from './Private';
import PublicRoute from './Public';
import Layout from '../Layout';
import Auth from "../components/Auth";
import NotFound from "../components/NotFound";

const history = createBrowserHistory();

const Routes = () => (
    <Router history={history}>
        <Suspense fallback={<Loader/>}>
            <Layout>
                <Auth/>
                <Switch>
                    {routes.map((route, i) => {
                        if (route.auth) {
                            return <PrivateRoute key={i} {...route}/>
                        }
                        return <PublicRoute key={i} {...route}/>
                    })}
                    <Route component={NotFound}/>
                </Switch>
            </Layout>
        </Suspense>
    </Router>
);

export default Routes;
