import { PUBLIC_PATH } from './constant';

// define image
export const Logo_White = `${PUBLIC_PATH}/image/logo/av-logo-wht1.png`;
export const Logo_noText = `${PUBLIC_PATH}/image/header/logo/logo-notext.png`;
export const Logo_Med = `${PUBLIC_PATH}/image/header/logo/logo-notext@2x.png`;
export const Logo_Lg = `${PUBLIC_PATH}/image/header/logo/logo-notext@3x.png`;
export const Logo = `${PUBLIC_PATH}/image/logo/av-logo.png`;
export const NoImage = `${PUBLIC_PATH}/image/no-image.jpg`;
export const LogoIcon = `${PUBLIC_PATH}/image/no-image-user.png`;
export const LogoIcon40 = `${PUBLIC_PATH}/image/no-image-user-40o.png`;
export const NoImgVideo = `${PUBLIC_PATH}/image/no-image-video.png`;
export const NoImgScoresheet = `${PUBLIC_PATH}/image/no-image-scoresheet.png`;
export const ImgOurTech = `${PUBLIC_PATH}/image/landing/our-tech/our-tech-hockey-ai.jpg`;
export const ImgOurTechMed = `${PUBLIC_PATH}/image/landing/our-tech/our-tech-hockey-ai.jpg`;
export const ImgOurTechLg = `${PUBLIC_PATH}/image/landing/our-tech/our-tech-hockey-ai.jpg`;
export const ImgOurTechStats = `${PUBLIC_PATH}/image/landing/tech-icon/bars.svg`;
export const ImgOurTechVideo = `${PUBLIC_PATH}/image/landing/tech-icon/social-media.svg`;
export const ImgOurTechAI = `${PUBLIC_PATH}/image/landing/tech-icon/technology-1.svg`;
export const staff_1 = `${PUBLIC_PATH}/image/staff/kartikeya-bhargava.jpg`;
export const staff_2 = `${PUBLIC_PATH}/image/staff/hemanth-pidaparthy.jpg`;
export const staff_3 = `${PUBLIC_PATH}/image/staff/james-elder.jpg`;
export const staff_4 = `${PUBLIC_PATH}/image/staff/andrew-tite.jpg`;
export const LogoVista = `${PUBLIC_PATH}/image/landing/partners/VISTA_YU_horiz_COLOUR_medium.png`;
export const LogoYorkU = `${PUBLIC_PATH}/image/landing/partners/YorkU.png`;
export const LogoNextAI = `${PUBLIC_PATH}/image/landing/partners/Next_AI_Blk.png`;
export const IcoLive = `${PUBLIC_PATH}/image/menu/icoLive-36.png`;
export const IcoLiveOn = `${PUBLIC_PATH}/image/menu/icoLive-36-on.png`;
export const IcoHistory = `${PUBLIC_PATH}/image/menu/icoHistory-36.png`;
export const IcoHistoryOn = `${PUBLIC_PATH}/image/menu/icoHistory-36-on.png`;
export const IcoHome = `${PUBLIC_PATH}/image/menu/icoHome-36.png`;
export const IcoHomeOn = `${PUBLIC_PATH}/image/menu/icoHome-36-on.png`;
export const IcoTeam = `${PUBLIC_PATH}/image/menu/icoTeam-36.png`;
export const IcoTeamOn = `${PUBLIC_PATH}/image/menu/icoTeam-36-on.png`;
export const IcoSettings = `${PUBLIC_PATH}/image/menu/icoSettings-36.png`;
export const IcoSettingsOn = `${PUBLIC_PATH}/image/menu/icoSettings-36-on.png`;
export const IcoStats = `${PUBLIC_PATH}/image/menu/icoStats-36.png`;
export const IcoStatsOn = `${PUBLIC_PATH}/image/menu/icoStats-36-on.png`;
export const IcoHelp = `${PUBLIC_PATH}/image/menu/icoHelp.svg`;
export const IcoFeedback = `${PUBLIC_PATH}/image/menu/icoFeedback.svg`;
export const IcoFilter = `${PUBLIC_PATH}/image/icon/filter.png`;
export const Opacity_logo = `${PUBLIC_PATH}/image/logo/bg-trans-7-500sm.png`;
export const LiveIcon = `${PUBLIC_PATH}/image/live/live1wht.png`;
export const SaveIcon = `${PUBLIC_PATH}/image/icon/save-24px.svg`;
export const FileNewIcon = `${PUBLIC_PATH}/image/icon/file/new-file.png`;
export const FileExistingDataIcon = `${PUBLIC_PATH}/image/icon/file/file-existing-data.png`;
export const EditHereIcon = `${PUBLIC_PATH}/image/icon/edit-here-75.png`;
export const ImgRink1 = `${PUBLIC_PATH}/image/live/rink1.jpg`;
export const ImgRinkPhone1 = `${PUBLIC_PATH}/image/live/rink-phone1.jpg`;
// sports icons
export const ImgIcoHockey = `${PUBLIC_PATH}/image/icon-sports/hockey.svg`;
export const ImgIcoFootball = `${PUBLIC_PATH}/image/icon-sports/football.svg`;
export const ImgIcoCricket = `${PUBLIC_PATH}/image/icon-sports/cricket.svg`;
export const ImgIcoTennis = `${PUBLIC_PATH}/image/icon-sports/tennis.svg`;
export const ImgIcoAmericanFootball = `${PUBLIC_PATH}/image/icon-sports/american-football.svg`;
