import React from 'react';
import PropTypes from 'prop-types';
import Header from "../components/Header";
import Footer from "../components/Footer/Footer";

const displayName = 'Public Layout';
const propTypes = {
    history: PropTypes.object,
    children: PropTypes.node.isRequired,
};

function PublicLayout({ children, history }) {
    let Landing = history.location.pathname === '/' || history.location.pathname === '/landing';

    return <div>
        <main className="fragment">
            <Header Landing={Landing}/>
            { children }
            <Footer Landing={Landing}/>
        </main>
    </div>;
}
PublicLayout.displayName = displayName;
PublicLayout.propTypes = propTypes;

export default PublicLayout;
