import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';
import {Provider} from 'react-redux';
import store from "./Redux/store";
import './index.scss';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Loader from "./components/Loader/Loader";

// const muiTheme = getMuiTheme({
//     palette: {
//         accent1Color: amber50
//     },
//     tabs: {
//         backgroundColor: blue800
//     }
// });
//injectTapEventPlugin()

ReactDOM.render(
    (
        (<MuiThemeProvider>
            <Provider store={store}>
                <Routes/>
            </Provider>
        </MuiThemeProvider>)
    ), document.getElementById('root')
);

//serviceWorker.unregister();
