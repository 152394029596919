import React, {useEffect, useState} from 'react';
import {FeedbackModal} from '../../utils/Info';
import {HelpSlidingPanel} from "../Help/Help";
import {Navbar, Spinner} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {Authenticate as ApiAuth, GetRecord} from "../../utils/Api";
import AWS from "aws-sdk";
import {StringToHex} from "../../utils/Conversion";
import './style/menu-left.scss';
import {
    IcoHistory,
    IcoHistoryOn,
    IcoLive,
    IcoLiveOn,
    IcoHome,
    IcoHomeOn,
    IcoSettings,
    IcoSettingsOn,
    // IcoStats,
    // IcoStatsOn,
    IcoTeam,
    IcoTeamOn,
    // LogoIcon40
} from "../../Assets/js/image";
import {ToggleLeftMenu} from "../../Layout/Private";

const s3 = new AWS.S3({
    region: 'ca-central-1',
    credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    }
});

const displayName = 'Menu Left';
const propTypes = {
    history: PropTypes.object,
    isSignedIn: PropTypes.string,
    signUpError: PropTypes.bool,
    signOut: PropTypes.func,
    signIn: PropTypes.func,
    signUp: PropTypes.func,
    auth: PropTypes.object,
    username: PropTypes.string,
    userData: PropTypes.object,
    activeURL: PropTypes.string,
    setActiveURL: PropTypes.func,
    S3UrlExpireSeconds: PropTypes.number,
    currentLeftMenuState: PropTypes.string,
    ToggleLeftMenu: PropTypes.func,
    location: PropTypes.any,
    setUserInfo: PropTypes.func,
};
const MenuLeft = props => {
    const {
        auth,
        username,
        userData,
        activeURL,
        setActiveURL,
        S3UrlExpireSeconds,
        currentLeftMenuState,
        ToggleLeftMenu
    } = props;
    const [accountId, setAccountId] = useState(false);
    const [user_id, setUser_id] = useState(null);
    const [first_name, setFirst_name] = useState(null);
    const [last_name, setLast_name] = useState(null);
    const [image, setImage] = useState(null);
    const [liveId, setLiveId] = useState(false);
    const liveVideoApiURI = process.env.REACT_APP_AV_API_URI + "live/broadcast/";
    // useEffect(() => {
    //     if (currentLeftMenuState === 'retract') {
    //         ToggleLeftMenu(false, true, false, false);
    //     } else {
    //         ToggleLeftMenu(false, false, true, false);
    //     }
    // })
    const signOut = () => {
        localStorage.removeItem('userData');
        localStorage.removeItem('videoData');
        localStorage.removeItem('thumbData');
        props.signOut()
        document.location.href = '/landing';
    };
    useEffect(() => {
        if (currentLeftMenuState === 'retract') {
            ToggleLeftMenu('retract');
        } else {
            ToggleLeftMenu('expand');
        }
    })
    useEffect(() => {
        if (!accountId && auth.info.username) {
            let userApiURI = process.env.REACT_APP_AV_API_URI + "users/" + StringToHex(auth.info.username) + "/";
            // Update UserInfo from User API to detect any changes in db for team
            if (userData && userData.account && auth.info && auth.info.username) {
                ApiAuth().then((data) => {
                    GetRecord(data['access'], userApiURI).then((resp) => {
                        const userData = JSON.parse(JSON.stringify(resp));
                        setAccountId(userData.account.id);
                    });
                });
                // setAccountId(userData.account.id);
            }
        }
    }, [auth]);
    useEffect(() => {
        props.history.listen(location => {
            if (location.pathname !== props.location.pathname) {
                props.location.pathname = location.pathname;
                setActiveURL(location.pathname);
            }
        });
    })
    useEffect(() => {
        if (userData) {
            setUser_id(userData.id);
            setFirst_name(userData.first_name);
            setLast_name(userData.last_name);
            setImage(userData.image);
        }
    }, [userData]);
    useEffect(() => {
        if (userData.account && !liveId) {
            ApiAuth().then((data) => {
                let url = liveVideoApiURI + '?account=' + userData.account;
                GetRecord(data['access'], url).then((resp) => {
                    if (resp.count) {
                        setLiveId(resp.results[0].id);
                    } else {
                        setLiveId(0);
                    }
                })
            })
        }
    }, [userData]);
    const goToLive = (account, liveId) => {
        let url = liveId > 0 ? '/watch/' + account + '/live' + liveId : '/live';
        if (window.innerWidth < 768) {
            window.location.href = url;
        } else {
            props.history.push(url);
        }
    };
    const goToHistory = async (e) => {
        let url = '/history';
        if (window.innerWidth < 768) {
            window.location.href = url;
        } else {
            props.history.push(url);
        }
    };
    const goToEventList = (e) => {
        let url = '/eventlist';
        if (window.innerWidth < 768) {
            window.location.href = url;
        } else {
            props.history.push(url);
        }
    };
    const goToTeamBuilder = (e) => {
        let url = '/team';
        if (window.innerWidth < 768) {
            window.location.href = url;
        } else {
            props.history.push(url);
        }
    };
    const goToSettings = (e) => {
        let url = '/settings';
        if (window.innerWidth < 768) {
            window.location.href = url;
        } else {
            props.history.push(url);
        }
    };
    const regenUserImage = () => {
        let userApiURI = process.env.REACT_APP_AV_API_URI + "users/" + StringToHex(username) + "/";
        ApiAuth().then((data) => {
            GetRecord(data['access'], userApiURI).then((resp) => {
                // Get URL for user image
                if (resp.image) {
                    const imageURL = 'static/' + resp.image;
                    const thumbURL = s3.getSignedUrl('getObject', {
                        Bucket: process.env.REACT_APP_AWS_S3_BUCKET_VIDEO_USERS,
                        Key: imageURL,
                        Expires: S3UrlExpireSeconds
                    });
                    resp.image = thumbURL;
                } else {
                    resp.image = false;
                }

                localStorage.setItem('userData', JSON.stringify(resp));
                const userData = JSON.parse(localStorage.getItem('userData'));
                props.setUserInfo(userData);
            });
        });
    };
    const leftMenuExpandWidth = 767;
    let leftMenuWidth;
    if (window.innerWidth < leftMenuExpandWidth) {
        leftMenuWidth = window.innerWidth > leftMenuExpandWidth ? 247 : 0;
    } else {
        leftMenuWidth = window.innerWidth > leftMenuExpandWidth ? 247 : 80;
    }
    let userSummaryDisplay = window.innerWidth < 768 ? 'none' : 'block';
    let userIconLeftDisplay = window.innerWidth < 768 ? 'block' : 'none';
    let style = {
        menuLeft: {
            width: leftMenuWidth + 'px'
        },
        menuLeftInner: {
            width: leftMenuWidth + 'px'
        },
        menuLeftUserIcon: {
            width: '40px',
            borderRadius: '50%'
        },
        userSummary: {
            display: userSummaryDisplay
        }
    };
    return (
        <div id="menuLeft" className="sidebar" style={style.menuLeft}>
            <div id="menuLeftInner" style={style.menuLeftInner}>
                <div id="userIconLeft" className={'text-center'} style={{display: userIconLeftDisplay}}>
                    <img src={image ? image : 'https://dev.attentivevision.ca/image/no-image-user.png'}
                         alt={first_name ? first_name : '' + last_name ? last_name : ''}
                         style={style.menuLeftUserIcon}
                         onError={() => {
                             regenUserImage()
                         }}/>
                </div>
                <div id="userSummary" className={'left-user-summary'} style={style.userSummary}>
                    {user_id ? (
                        <div>
                            <img src={image ? image : 'https://dev.attentivevision.ca/image/no-image-user.png'}
                                 className={'left-avatar'}
                                 alt={first_name ? first_name : '' + last_name ? last_name : ''}
                                 onError={() => {
                                     regenUserImage()
                                 }}/>
                            <p className={'left-name'}>{first_name ? first_name : ''} {last_name ? last_name : ''}</p>
                            <p className={'left-position'} style={{display: 'none'}}>Coach</p>
                            {signOut ? (
                                <a href={'#'} onClick={signOut}>Sign out</a>
                            ) : null}
                        </div>
                    ) : (
                        <div>
                            <Spinner size={'sm'} animation={'border'}/>
                        </div>
                    )}

                </div>
                <div className={'left-menu-items'}>
                    <Navbar className={activeURL && activeURL.search("eventlist") > -1 ? 'selected' : ' '}
                            onClick={() => {
                                goToEventList();
                            }}>
                        <NavLink to={'/eventlist'}>
                            <div className={'activeAccentLeftBox'}/>
                            <Navbar.Brand className={'d-flex align-items-center'}>
                                <img src={
                                    activeURL && activeURL.search("eventlist") > -1 ? IcoHomeOn : IcoHome} alt=""/>
                                Dashboard
                            </Navbar.Brand>
                        </NavLink>
                    </Navbar>
                    <Navbar className={activeURL && activeURL.search("live") > -1 ? 'selected' : ' '}
                            onClick={goToLive}>
                        <NavLink to={'#'}>
                            <div className={'activeAccentLeftBox'}/>
                            <Navbar.Brand className={'d-flex align-items-center'}>
                                <img src={
                                    activeURL && activeURL.search("live") > -1 ? IcoLiveOn : IcoLive} alt=""/>
                                Live
                            </Navbar.Brand>
                        </NavLink>
                    </Navbar>
                    <Navbar className={activeURL && activeURL.search("history") > -1 ? 'selected' : ' '}
                            onClick={async () => {
                                if (window.innerWidth < 768)
                                    ToggleLeftMenu('retract');
                                await goToHistory();
                            }}>
                        <NavLink to={'/history'}>
                            <div className={'activeAccentLeftBox'}/>
                            <Navbar.Brand className={'d-flex align-items-center'}>
                                <img src={
                                    activeURL && activeURL.search("history") > -1 ? IcoHistoryOn : IcoHistory} alt=""/>
                                History
                            </Navbar.Brand>
                        </NavLink>
                    </Navbar>
                    {/*<Navbar className={activeURL.search("scoresheet") > -1 ? 'selected' : ' '} onClick={goToScoresheet}>*/}
                    {/*    <NavLink to={'/scoresheet'}>*/}
                    {/*        <div className={'activeAccentLeftBox'}/>*/}
                    {/*        <Navbar.Brand className={'d-flex align-items-center'}>*/}
                    {/*            <img src={*/}
                    {/*                activeURL.search("scoresheet") > -1 ? IcoStatsOn : IcoStats} alt=""/>*/}
                    {/*            Scoresheet*/}
                    {/*        </Navbar.Brand>*/}
                    {/*    </NavLink>*/}
                    {/*</Navbar>*/}
                    <Navbar className={activeURL && activeURL.search("team") > -1 ? 'selected' : ' '}
                            onClick={async () => {
                                if (window.innerWidth < 768)
                                    ToggleLeftMenu('retract');
                                await goToTeamBuilder();
                            }}>
                        <NavLink to={'/team'}>
                            <div className={'activeAccentLeftBox'}/>
                            <Navbar.Brand className={'d-flex align-items-center'}>
                                <img src={
                                    activeURL && activeURL.search("team") > -1 ? IcoTeamOn : IcoTeam} alt=""/>
                                Team
                            </Navbar.Brand>
                        </NavLink>
                    </Navbar>
                    {/*<Navbar className={activeURL.search("stats") > -1 ? 'selected' : ' '} onClick={goToStats}>*/}
                    {/*    <NavLink to={'/stats'}>*/}
                    {/*        <div className={'activeAccentLeftBox'}/>*/}
                    {/*        <Navbar.Brand className={'d-flex align-items-center'}>*/}
                    {/*            <img src={*/}
                    {/*                activeURL.search("stats") > -1 ? IcoStatsOn : IcoStats} alt=""/>*/}
                    {/*            Stats*/}
                    {/*        </Navbar.Brand>*/}
                    {/*    </NavLink>*/}
                    {/*</Navbar>*/}
                    <Navbar className={activeURL && activeURL.search("settings") > -1 ? 'selected' : ' '}
                            onClick={async () => {
                                await goToSettings();
                                if (window.innerWidth < 768)
                                    ToggleLeftMenu('retract');
                            }}>
                        <NavLink to={'/settings'}>
                            <div className={'activeAccentLeftBox'}/>
                            <Navbar.Brand className={'d-flex align-items-center'}>
                                <img src={
                                    activeURL && activeURL.search("settings") > -1 ? IcoSettingsOn : IcoSettings} alt=""/>
                                Settings
                            </Navbar.Brand>
                        </NavLink>
                    </Navbar>
                    <Navbar>
                        <div className={'activeAccentLeftBox'}/>
                        <Navbar.Brand className={'d-flex align-items-center'} href="#" onClick={(e) => {
                            e.preventDefault()
                        }}>
                            <FeedbackModal Icon={true}/>
                        </Navbar.Brand>
                    </Navbar>
                    <Navbar>
                        <div className={'activeAccentLeftBox'}/>
                        <Navbar.Brand className={'d-flex align-items-center'} href="#" onClick={(e) => {
                            e.preventDefault()
                        }}>
                            <HelpSlidingPanel {...props}/>
                        </Navbar.Brand>
                    </Navbar>
                </div>
            </div>
        </div>
    )
}
MenuLeft.displayName = displayName;
MenuLeft.propTypes = propTypes;
export default (withRouter(MenuLeft));
