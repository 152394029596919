import React, {Component, useEffect, useState} from 'react'
import PropTypes from 'prop-types';
import Header from "../Header";
import Footer from "../Footer/Footer";
import MenuLeft from "../Menu/MenuLeft";
import ReactSlidingPane from "react-sliding-pane";
import 'react-sliding-pane/dist/react-sliding-pane.css';
import {Authenticate as ApiAuth, GetRecord, PostRecord} from "../../utils/Api";
import HTMLReactParser from "html-react-parser";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowCircleLeft, faComment, faFileAlt, faInfoCircle, faTimes} from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import {Alert, Button, Spinner, Table} from "react-bootstrap";
import {ContactForm} from "../../screens/Contact/Contact";
// import ApiConfig from "../../Config/ApiConfig";
import {IcoHelp} from "../../Assets/js/image";
import './style/help.scss';

// const popularArticlesApiURI = ApiConfig.REACT_APP_AV_API_URI + "answers/pop/";
// const articlesApiURI = ApiConfig.REACT_APP_AV_API_URI + "answers/a/";
// const feedbackApiURI = ApiConfig.REACT_APP_AV_API_URI + "clientcare/feedback/";
const popularArticlesApiURI = process.env.REACT_APP_AV_API_URI + "answers/pop/";
const articlesApiURI = process.env.REACT_APP_AV_API_URI + "answers/a/";
const feedbackApiURI = process.env.REACT_APP_AV_API_URI + "clientcare/feedback/";

export const FeedbackForm = () => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const [validated, setValidated] = useState(false);
    const [sent, setSent] = useState(false);
    const [sendInProgress, setSendInProgress] = useState(false);
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    useEffect(() => {
        if (email === '' && userData) {
            setEmail(userData.email);
        }
    })
    const handleSubmit = (evt) => {
        const form = evt.currentTarget;
        if (form.checkValidity() === false) {
            evt.stopPropagation();
        }

        setValidated(true);

        if (validated) {
            setSendInProgress(true);

            let feedbackData = {
                "email": email,
                "message": message
            };

            // Send feedback to API
            ApiAuth().then((data) => {
                PostRecord(data['access'], feedbackApiURI.toString(), feedbackData).then((resp) => {
                    // Success
                    if (resp.id) {
                        setEmail("");
                        setMessage("");
                        setSent(true);
                    }
                });
            }).then((resp) => {
                setSendInProgress(false);
            });
        }

        evt.preventDefault();
    };

    return (
        <Form onSubmit={handleSubmit} noValidate validated={validated}>
            {sent ? (
                <Alert variant={'success'}>
                    Thank you for your feedback!
                </Alert>
            ) : ''}
            <Form.Group controlId="validationCustom01">
                <Form.Control
                    required
                    type="email"
                    placeholder="Your Email Address"
                    value={email}
                    disabled={sent ? true : false}
                    onChange={e => setEmail(e.target.value)}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formFeedbackMessage">
                <Form.Control
                    required
                    as="textarea"
                    placeholder="Leave product feedback or share your ideas. This is not a way to contact us as you will not receive a response."
                    value={message}
                    rows="8"
                    disabled={sent}
                    onChange={e => setMessage(e.target.value)}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            {!sent ?
                sendInProgress ? (
                    <Button variant="primary" type="submit" disabled>
                        <Spinner animation="border" role="status" size="sm">
                            <span className="sr-only">Sending... </span>
                        </Spinner> Sending...
                    </Button>
                ) : (
                    <Button variant="primary" type="submit" onClick={handleSubmit}>
                        Send
                    </Button>
                )
                : ''}
        </Form>
    )
};

export class HelpSlidingPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPaneOpen: false,
            helpPaneIcon: '',
            popularArticles: false,
            articleSearchLoading: false,
            searchTerms: '',
            articleSearchResults: false,
            articleLoading: false,
            activeArticleRight: false,
            activeContactFormRight: false,
            activeFeedbackFormRight: false
        };
    }

    popularArticles = () => {
        ApiAuth().then((data) => {
            GetRecord(data['access'], popularArticlesApiURI).then((resp) => {
                this.setState((prevState, props) => {
                    return {popularArticles: resp.results}
                });
            });
        });
    };

    handleContactFormOpenSide = (id) => {
        this.setState((prevState, props) => {
            return {activeContactFormRight: true};
        })
    };

    handleFeedbackFormOpenSide = (id) => {
        this.setState((prevState, props) => {
            return {activeFeedbackFormRight: true};
        })
    };

    handleArticleOpenSide = (id) => {
        this.setState((prevState, props) => {
            return {articleLoading: true};
        });
        ApiAuth().then((data) => {
            GetRecord(data['access'], articlesApiURI + id + '/').then((resp) => {
                this.setState((prevState, props) => {
                    return {
                        activeArticleRight: resp,
                        articleLoading: false
                    }
                });
            });
        });
    };

    handleArticleSearchSide = (evt) => {
        this.setState((prevState, props) => {
            return {articleSearchResults: false};
        });
        let search_term = evt.target.value ? evt.target.value : '';

        if (search_term) {
            this.setState((prevState, props) => {
                return {articleSearchLoading: true};
            });
            ApiAuth().then((data) => {
                GetRecord(data['access'], articlesApiURI + '?search=' + search_term).then((resp) => {
                    this.setState((prevState, props) => {
                        return {
                            articleSearchResults: resp.results,
                            articleSearchLoading: false
                        };
                    });
                });
            });
        }
    };

    handleArticleClose = () => {
        this.setState((prevState, props) => {
            return {
                activeArticleRight: false,
                articleLoading: false,
                activeContactFormRight: false,
                activeFeedbackFormRight: false
            };
        });
    };

    goToArticles = () => {
        this.setState((prevState, props) => {
            return {isPaneOpen: false};
        });
        this.props.history.push('/answers/all');
    };

    handleHelpClick = () => {
        this.setState((prevState, props) => {
            return {isPaneOpen: true};
        })
    };

    handleHelpClose = () => {
        this.setState((prevState, props) => {
            return {
                isPaneOpen: false,
                helpPaneIcon: '',
                popularArticles: false,
                searchText: '',
                articleLoading: false,
                activeArticleRight: false,
                activeContactFormRight: false,
                activeFeedbackFormRight: false,
                activeSearchFormRight: false
            };
        })
    };

    handleContactFormClose = () => {
        this.setState((prevState, props) => {
            return {activeContactFormRight: false};
        });
    };

    handleFeedbackFormClose = () => {
        this.setState((prevState, props) => {
            return {activeFeedbackFormRight: false};
        });
    };

    handleSearchForm = searchTerms => {
        this.setState((prevState, props) => {
            return {searchTerms: searchTerms};
        });
    };

    render() {
        let style = {
            docTableTr: {
                cursor: "pointer"
            }
        };

        if (!this.state.popularArticles) {
            this.popularArticles();
        }

        return <div className={'w-100 h-100'} style={{display: 'contents'}}>
            <div className="w-100 h-100 d-flex align-items-center" onClick={() => this.handleHelpClick()}>
                <img src={IcoHelp} alt={'Get Help'}/> Help
            </div>

            <ReactSlidingPane
                closeIcon={<FontAwesomeIcon icon={faTimes}/>}
                isOpen={this.state.isPaneOpen}
                title='Assistance'
                subtitle='How can we assist you today?'
                from='right'
                width='100%'
                ariaHideApp={true}
                overlayClassName='help-slide-panel'
                onRequestClose={() => this.handleHelpClose()}>
                <div>
                    {this.state.articleLoading ? (
                        // If loading article show spinner
                        <div style={{height: '150px'}}><Spinner animation="border" size="sm"/> Loading Articles</div>
                    ) : (
                        // CONTACT FORM
                        this.state.activeContactFormRight ? (
                            // Show Contact Form if active
                            <div style={{minHeight: '150px'}}>
                                <div className={'contact-form-header'}>
                                    <FontAwesomeIcon icon={faArrowCircleLeft}
                                                     onClick={(e) => this.handleContactFormClose()}
                                                     style={{
                                                         position: 'absolute',
                                                         cursor: 'pointer',
                                                         marginTop: '5px'
                                                     }}/>
                                    <h1>Contact Form</h1>
                                </div>
                                <div className={'contact-form-body'}>
                                    <ContactForm/>
                                </div>
                            </div>
                        ) : (
                            // FEEDBACK FORM
                            this.state.activeFeedbackFormRight ? (
                                // Show Feedback Form if active
                                <div style={{minHeight: '150px'}}>
                                    <div className={'contact-form-header'}>
                                        <FontAwesomeIcon icon={faArrowCircleLeft}
                                                         onClick={(e) => this.handleFeedbackFormClose()}
                                                         style={{
                                                             position: 'absolute',
                                                             cursor: 'pointer',
                                                             marginTop: '5px'
                                                         }}/>
                                        <h1>Feedback Form</h1>
                                    </div>
                                    <div className={'contact-form-body'}>
                                        <FeedbackForm/>
                                    </div>
                                </div>
                            ) : (
                                // ACTIVE ARTICLE
                                this.state.activeArticleRight ? (
                                    // Show Active Article if active
                                    <section>
                                        <div style={{minHeight: '150px'}}>
                                            <div className={'article-header'}>
                                                <FontAwesomeIcon icon={faArrowCircleLeft}
                                                                 onClick={this.handleArticleClose}
                                                                 style={{
                                                                     position: 'absolute',
                                                                     cursor: 'pointer',
                                                                     marginTop: '5px'
                                                                 }}/>
                                                <h1>{this.state.activeArticleRight.title}</h1>
                                                <hr/>
                                            </div>
                                            <div className={'article-body'}>
                                                {HTMLReactParser(this.state.activeArticleRight.content)}
                                            </div>
                                        </div>
                                    </section>
                                ) : (
                                    // POPULAR HELP RESOURCES if nothing is active
                                    <Form>
                                        <Form.Group>
                                            <Form.Control type="text" placeholder="Search Help"
                                                          value={this.state.searchTerms}
                                                          onChange={e => this.handleSearchForm(e.target.value)}
                                                          onKeyUp={this.handleArticleSearchSide}/>
                                        </Form.Group>

                                        {this.state.articleSearchLoading ? (
                                            // If search articles show spinner
                                            <div style={{height: '150px'}}>
                                                <Spinner animation="border" size="sm"/> Searching
                                            </div>
                                        ) : (
                                            this.state.articleSearchResults ? (
                                                <section>
                                                    <p className={'text-muted'}>Search Results</p>

                                                    <Table hover responsive={'sm'}>
                                                        <tbody>
                                                        {this.state.articleSearchResults.length ? (
                                                            Object.entries(this.state.articleSearchResults).map(([key, value]) =>
                                                                <tr style={style.docTableTr} onClick={(e) => {
                                                                    this.handleArticleOpenSide(value.id)
                                                                }} key={key}>
                                                                    <td><FontAwesomeIcon icon={faFileAlt}/></td>
                                                                    <td>{value.title}</td>
                                                                </tr>
                                                            )
                                                        ) : (
                                                            <div>No help resources matching your serach</div>
                                                        )}
                                                        </tbody>
                                                    </Table>
                                                </section>
                                            ) : (
                                                <section>
                                                    <p className={'text-muted'}>Popular help resources</p>

                                                    <Table hover responsive={'sm'}>
                                                        <tbody>
                                                        {this.state.popularArticles ? (
                                                            Object.entries(this.state.popularArticles).map(([key, value]) =>
                                                                <tr key={key} style={style.docTableTr} onClick={(e) => {
                                                                    this.handleArticleOpenSide(value.id)
                                                                }}>
                                                                    <td><FontAwesomeIcon icon={faFileAlt}/></td>
                                                                    <td>{value.title}</td>
                                                                </tr>
                                                            )
                                                        ) : (
                                                            <tr>
                                                                <td className={'text-center'}>
                                                                    <Spinner animation="border" size="sm"/>
                                                                    Loading Articles...
                                                                </td>
                                                            </tr>
                                                        )}
                                                        <tr style={style.docTableTr}>
                                                            <td colSpan={'2'} className={'text-center'}>
                                                                <div onClick={this.goToArticles}
                                                                     style={{color: '#1d90c6'}}>
                                                                    BROWSE ALL ARTICLES &#x1F5D7;
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </Table>
                                                </section>
                                            )
                                        )}
                                    </Form>
                                )
                            )
                        )
                    )}

                    {!this.state.activeContactFormRight && !this.state.activeFeedbackFormRight ? (
                        <section className={'mt-2'}>
                            <p className={'text-muted'}>Contact us</p>
                            <Table hover responsive={'sm'}>
                                <tbody>
                                <tr style={style.docTableTr}>
                                    <td><FontAwesomeIcon icon={faInfoCircle}/></td>
                                    <td onClick={this.handleContactFormOpenSide}>Need More Help?</td>
                                </tr>
                                <tr style={style.docTableTr}>
                                    <td><FontAwesomeIcon icon={faComment}/></td>
                                    <td onClick={this.handleFeedbackFormOpenSide}>Send Feedback</td>
                                </tr>
                                </tbody>
                            </Table>
                        </section>
                    ) : ''}
                </div>
            </ReactSlidingPane>
        </div>;
    }
}
HelpSlidingPanel.propTypes = {
    history: PropTypes.object,
}

export const HelpComponent = () => {
    return (
        <div>
            <Header headerType="condensed"/>
            <div className="row">
                <div className="col-xl-2 col-lg-3 col-md-4">
                    <MenuLeft/>
                </div>
                <div className="col-xl-10 col-lg-9 col-md-8">
                    <HelpSlidingPanel/>
                </div>
            </div>
            <Footer/>
        </div>
    )
};
