import React from 'react';
import {Link as LinkScroll} from "react-scroll";
import {Link} from "react-router-dom";
import {Nav, Navbar} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faSignInAlt, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import AutosuggestSearch from "./AutoSuggestSearch";
import {ToggleLeftMenu} from "../../Layout/Private";
import './style/menu-top.scss';

const displayName = 'Menu Top';
const propTypes = {
    isSignedIn: PropTypes.string,
    signUpError: PropTypes.bool,
    signOut: PropTypes.func,
    signIn: PropTypes.func,
    signUp: PropTypes.func,
    auth: PropTypes.object,
    Landing: PropTypes.bool,
    navExpanded: PropTypes.bool,
    accountId: PropTypes.string,
};
class MenuTop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navExpanded: false,
            headerSearchResults: false,
            account_id: null
        };
        this.handleIdChange = this.handleIdChange.bind(this);
    }

    componentDidMount() {
        const {accountId} = this.props;
        if (accountId) {
            this.handleIdChange(accountId);
        }
    }

    handleIdChange(accountId) {
        this.setState((prevState, props) => {
            return {account_id: accountId};
        })
    }

    closeNav() {
        this.setState((prevState, props) => {
            return {navExpanded: false};
        });
    }

    signOut = () => {
        localStorage.removeItem('userData');
        localStorage.removeItem('videoData');
        localStorage.removeItem('thumbData');
        this.props.signOut();
        document.location.href = '/landing';
    };

    render() {
        const {auth, Landing} = this.props;
        const {accountId} = this.props;
        let loggedIn;
        loggedIn = auth.isSignedIn === 'AUTH_SUCCESS';

        const setMenuDims = () => {
            ToggleLeftMenu('retract');
        }
        setMenuDims();
        window.addEventListener("resize", setMenuDims);
        return (
            <Navbar onToggle={(e) => {
                this.setState((prevState, props) => {
                    return {navExpanded: !this.state.navExpanded};
                });
            }}
                    expand="lg"
                    className={'h-100 p-0 top-search-find-wrapper'}>
                {loggedIn ? (
                    <Navbar.Toggle aria-controls="topbarNav"
                                   style={{color: '#fff', marginRight: 50, padding: '9px 15px'}}>
                        <FontAwesomeIcon icon={faSearch}/>
                    </Navbar.Toggle>
                ) : false}
                {!loggedIn ? (
                    <div className={'top-signin-mobile'}>
                        <Link to={'/signin'}>
                            <FontAwesomeIcon icon={faSignInAlt} size={'2x'} style={{margin: '10px 0'}}/>
                        </Link>
                    </div>
                ) : (
                    <div className={'top-signout-mobile'}>
                        <FontAwesomeIcon title={'Sign out'} icon={faSignOutAlt} className="mr-1"
                                         style={{
                                             float: 'right',
                                             fontSize: '30px',
                                             margin: '10px 0 0 16px',
                                             marginRight: '0px',
                                             color: '#fff',
                                             cursor: 'pointer'
                                         }} onClick={() => this.signOut()}/>
                    </div>
                )}

                {/*<Navbar.Toggle aria-controls="topbarNav" style={{color: '#fff', marginRight: 50, padding: '5px 12px'}}/>*/}

                <Navbar.Collapse id="topbarNav">
                    {loggedIn ? (
                        <Nav className="ml-auto d-flex align-items-center">
                            <Nav.Item>
                                <div className={'has-search'}>
                                    <FontAwesomeIcon className="form-control-search" icon={faSearch}/>
                                    <AutosuggestSearch account_Id={accountId}/>
                                </div>
                                <div className={'top-signout'}>
                                    <FontAwesomeIcon title={'Sign out'} icon={faSignOutAlt} className="mr-1"
                                                     style={{
                                                         float: 'right',
                                                         fontSize: '30px',
                                                         margin: '10px 0 0 16px',
                                                         marginRight: '0px',
                                                         color: '#fff',
                                                         cursor: 'pointer'
                                                     }} onClick={() => this.signOut()}/>
                                </div>
                            </Nav.Item>
                        </Nav>
                    ) : (
                        Landing ?
                            <Nav className="ml-auto d-flex align-items-center">
                                <Nav.Item>
                                    <LinkScroll href={'#'} to="section-our-technology" className={'nav-link'}>
                                        Our Technology
                                    </LinkScroll>
                                </Nav.Item>
                                <Nav.Item>
                                    <LinkScroll href={'#'} to="section-our-story" className={'nav-link'}>
                                        Our Story
                                    </LinkScroll>
                                </Nav.Item>
                                <NavLink to={'/contact'}>Contact Us</NavLink>
                                <NavLink to={'/signin'} className={'d-lg-none'}>
                                    <div>Login</div>
                                </NavLink>
                            </Nav>
                            :
                            <Nav className="ml-auto d-flex align-items-center">
                                <NavLink to={'/landing'} className={'nav-link'}>Home</NavLink>
                                <NavLink to={'/contact'} className={'nav-link'}>Contact Us</NavLink>
                                <NavLink to={'/signin'}>
                                    <div>Login</div>
                                </NavLink>
                            </Nav>
                    )}
                </Navbar.Collapse>
            </Navbar>
        )
    }
}
MenuTop.displayName = displayName;
MenuTop.propTypes = propTypes;
export default MenuTop;
