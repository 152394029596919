import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";

const displayName = 'Air Hockey Mini Game';
const propTypes = {
    parentElX: PropTypes.number,
    parentElY: PropTypes.number,
};
const AirHockey = props => {
    const {parentElX, parentElY} = props;
    let gm = true;
    let ball_speed = 10;
    let xspeed = 0;
    let yspeed = 0;
    let com_score = 0;
    let player_score = 0;
    let x_min = 30;
    let x_max = 460;
    let y_min = 30;
    let y_max = 600;
    let ctx = false;
    let canvas = false;
    const [canvasWidth, setCanvasWidth] = useState(520);
    const [canvasHeight, setCanvasHeight] = useState(660);

    useEffect(() => {
        canvas = document.getElementById('canvas');
        ctx = canvas.getContext('2d');
        canvas.addEventListener("mousemove", mouseMoveHandler, false);
    })

    function draw_rect(x, y, w, h, b) {
        ctx.beginPath();
        if (b) {
            ctx.strokeStyle = "#151B54";
            ctx.lineWidth = 40;
        } else {
            ctx.strokeStyle = "#265d7d";
            ctx.lineWidth = 4;
        }
        ctx.strokeRect(x, y, w, h);
        ctx.closePath();
    }

    function draw_goal(x, y, r, s) {
        ctx.beginPath();
        ctx.lineWidth = 4;
        if (s)
            ctx.arc(x, y, r, 0, Math.PI, false);
        else
            ctx.arc(x, y, r, Math.PI, 0, false);

        ctx.strokeStyle = "#1589FF";
        ctx.stroke();
        ctx.closePath();
    }

    function draw_circle(x, y, r, w, img = null) {
        ctx.beginPath();
        ctx.lineWidth = w;
        ctx.arc(x, y, r, 0, Math.PI * 2, false);
        ctx.globalAlpha = 0.8;
        if (img) {
            let img1 = new Image;
            img1.src = img;
            ctx.drawImage(img1, x - 65, y - 45, 130, 90);
        }
        ctx.globalAlpha = 1;
        ctx.strokeStyle = "#1589FF";
        ctx.stroke();
        ctx.closePath();
    }

    function draw_filled_circle(x, y, r, d) {
        ctx.beginPath();
        ctx.arc(x, y, r, 0, Math.PI * 2);
        if (d == 1) {
            ctx.fillStyle = "#F87217";
            ctx.strokeStyle = "#9F000F";
        } else if (d == 2) {
            ctx.fillStyle = "#ffdc00";
            ctx.strokeStyle = "#9F000F";
        } else {
            ctx.fillStyle = "#7D0552";
            ctx.strokeStyle = "#4CC417";
        }

        ctx.fill();
        ctx.lineWidth = 3;

        ctx.stroke();
        ctx.closePath();
    }

    function draw_board() {
        draw_rect(0, 0, 520, 660, 1);
        draw_rect(30, 30, 460, 600, 0);
        draw_goal(260, 30, 70, 1);
        draw_goal(260, 30, 150, 1);
        draw_goal(260, 630, 70, 0);
        draw_goal(260, 630, 150, 0);
        draw_circle(260, 330, 40, 4, "https://dev.attentivevision.ca/image/logo/av-logo.png");
        draw_circle(260, 330, 5, 4);

        ctx.beginPath();
        ctx.moveTo(30, 330);
        ctx.lineTo(490, 330);
        ctx.stroke();
        ctx.closePath();

        ctx.beginPath();
        ctx.moveTo(190, 30);
        ctx.lineTo(330, 30);
        ctx.lineWidth = 4;
        ctx.strokeStyle = "#000";
        ctx.stroke();
        ctx.closePath();

        ctx.beginPath();
        ctx.moveTo(190, 630);
        ctx.lineTo(330, 630);
        //ctx.strokeStyle("#FFFFFF");
        ctx.stroke();
        ctx.closePath();

        ctx.font = "50px Pristina";
        ctx.lineWidth = 2
        ctx.strokeText(com_score, 440, 300);
        ctx.strokeText(player_score, 440, 380);
    }

    function distance(x1, y1, x2, y2) {
        let tempx = x2 - x1;
        let tempy = y2 - y1;
        tempx *= tempx;
        tempy *= tempy;
        return Math.sqrt(tempx + tempy);
    }


    let Mallet = function (x, y, r) {
        this.x = x;
        this.y = y;
        this.radius = r;
    }
    // Player's object
    let pMallet = new Mallet(260, canvasHeight - 100, 30);

    let cMallet = new Mallet(260, 100, 30);

    // Ball class
    let Ball = function (x, y, r) {
        this.x = x;
        this.y = y;
        this.radius = r;
    }

    // ball object
    let ball = new Ball(canvasWidth / 2, canvasHeight - 200, 15);

    // function to control the mallet according to cursor
    function mouseMoveHandler(e) {
        if (canvas) {
            let relativeX = e.clientX - canvas.parentElement.offsetLeft - canvas.offsetLeft;
            let relativeY = e.clientY - canvas.parentElement.offsetTop - canvas.offsetTop;
            // horizonal position (X)
            if (relativeX > (canvas.offsetLeft + 50) && relativeX < (canvasWidth - 65)) {
                pMallet.x = relativeX;
            }
            // vertical position (Y)
            if (relativeY > (canvas.offsetTop + 15) && relativeY < (canvasHeight - 60)) {
                pMallet.y = relativeY;
            }
        }
    }

    function play() {

        if (ctx) {
            ctx.clearRect(0, 0, canvasWidth, canvasHeight);
            // draw draw the board
            draw_board();
            // drawing player's mallet
            draw_filled_circle(pMallet.x, pMallet.y, pMallet.radius, 1);
            // drawing computer's mallet
            draw_filled_circle(cMallet.x, cMallet.y, cMallet.radius, 2);
            // drawing the ball
            draw_filled_circle(ball.x, ball.y, ball.radius, 0);

            // condition to counce the ball off the left-right walls
            if (ball.x + xspeed > canvasWidth - ball.radius - 30 || ball.x + xspeed < ball.radius + 30) {
                xspeed *= -1;
            }

            // condition to bounce the ball off the top-botom walls and goal logic
            if (ball.x > 190 && ball.x < 330) {
                if (ball.y + yspeed > canvasHeight + ball.radius - 30) {
                    console.log("Computer Score");
                    ball.x = canvasWidth / 2;
                    ball.y = canvasHeight / 2 + 100;
                    xspeed = 0;
                    yspeed = 0;
                    com_score = com_score + 1;
                } else if (ball.y + yspeed < 30 - ball.radius) {
                    console.log("you Score");
                    ball.x = canvasWidth / 2;
                    ball.y = canvasHeight / 2 - 100;
                    xspeed = 0;
                    yspeed = 0;
                    player_score = player_score + 1;
                }
            } else {
                if (ball.y + yspeed > canvasHeight - ball.radius - 30 || ball.y + yspeed < 30 + ball.radius) {
                    yspeed *= -1;
                }
            }


            let ed = false; // enemy difficulty
            let er = 1;//Used in AI. This is so CPU doesn't just hit it down all the time.
            let p2s;//The speed CPU moves side to side
            if (ed) {
                er = 5;
            }//If hard, Make er larger so CPU hits it diagonally.


            if ((Math.abs(xspeed) + Math.abs(yspeed)) < 10 && ball.y <= canvasHeight / 2) {
                if (ball.y - 20 > cMallet.y) {
                    cMallet.y += 2;
                } else {
                    cMallet.y -= 2;
                }
            } else if (cMallet.y > 100) {
                cMallet.y -= 2;
            } else if (cMallet.y < 100) {
                cMallet.y += 2;
            }


            //Make sure You or CPU doesn't go past the line or go off screen.
            if (cMallet.x < x_min) {
                cMallet.x = x_min + 30;
            }
            if (cMallet.x > x_max) {
                cMallet.x = x_max + 30;
            }
            if (cMallet.y < y_min) {
                cMallet.y = y_min + 60;
            }
            if (cMallet.y > y_max) {
                cMallet.y = y_max;
            }

            //set CPU's speed depending on difficulty
            if (!ed) {
                p2s = 2;
            } else {
                p2s = 3;
            }

            //If the ball is behind CPU, it moves out of the way.
            if (ball.y < cMallet.y && ball.x > cMallet.x - 30 && ball.x < cMallet.x + 30) {
                p2s = -2;
            }
            //Make CPU move towards the ball's x coord
            if (cMallet.x < ball.x + er) {
                cMallet.x += p2s;
            }
            if (cMallet.x > ball.x - er) {
                cMallet.x -= p2s;
            }


            let pDist = distance(pMallet.x, pMallet.y, ball.x, ball.y);

            let cDist = distance(cMallet.x, cMallet.y, ball.x, ball.y);


            // Function to hit the ball for player
            if (pDist < 45) {
                /* console.log("Mallet x : "+pMallet.x+" y : "+pMallet.y);
                 console.log("Ball x : "+ball.x+" y : "+ball.y);
                 console.log("Distance is : "+dist);
                 console.log("dist<30"); */
                let dx = ball.x - pMallet.x;
                let dy = ball.y - pMallet.y;
                //console.log("dx  : " + dx);
                //console.log("dy  : " + dy);
                dx /= 30;
                dy /= 30;
                xspeed = dx * ball_speed;
                yspeed = dy * ball_speed;
            }

            // Function to hit the ball when computer is playing
            if (cDist < 45) {
                let cdx = ball.x - cMallet.x;
                let cdy = ball.y - cMallet.y;
                cdx /= 45;
                cdy /= 45;
                xspeed = cdx * ball_speed;
                yspeed = cdy * ball_speed;
            }

            // Adjustments in the x and y coordianate of  the ball
            ball.x += xspeed;
            ball.y += yspeed;

            xspeed *= 0.99;
            yspeed *= 0.99;
        }
    }

    setInterval(play, 10);

    return (
        <canvas id="canvas" width={canvasWidth} height={canvasHeight} />
    )
}
AirHockey.displayName = displayName;
AirHockey.propTypes = propTypes;
export default AirHockey;
