import React, {useEffect, useState} from 'react'
import {Alert, Button, Card, Col, Modal, Row, Spinner, Table} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {Authenticate as ApiAuth, PostRecord} from "../utils/Api";
import InputMask from 'react-input-mask';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faKeyboard} from "@fortawesome/free-solid-svg-icons";
import {IcoFeedback} from "../Assets/js/image";

const feedbackApiURI = process.env.REACT_APP_AV_API_URI + "clientcare/feedback/";

export const ready = (callback) => {
    // in case the document is already rendered
    if (document.readyState !== 'loading') callback();
    // modern browsers
    else if (document.addEventListener) document.addEventListener('DOMContentLoaded', callback);
    // IE <= 8
    else document.attachEvent('onreadystatechange', function () {
            if (document.readyState === 'complete') callback();
        });
};

/**
 * Get the URL parameters
 * source: https://css-tricks.com/snippets/javascript/get-url-variables/
 * @param  {String} url The URL
 * @return {Object}     The URL parameters
 */
export const getParams = function (url) {
    let params = {};
    let parser = document.createElement('a');
    parser.href = url;
    let query = parser.search.substring(1);
    let vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
};

export const FeedbackModal = (props) => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [sent, setSent] = useState(false);
    const [sendInProgress, setSendInProgress] = useState(false);
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    useEffect(() => {
        if (email === '' && userData) {
            setEmail(userData.email);
        }
    })
    const handleClose = () => {
        setEmail("");
        setMessage("");
        setSent(false);
        setShow(false);
    };
    const handleShow = () => setShow(true);
    const handleSubmit = (evt) => {
        const form = evt.currentTarget;
        if (form.checkValidity() === false) {
            evt.stopPropagation();
        }

        setValidated(true);

        if (validated) {
            setSendInProgress(true);

            let feedbackData = {
                "email": email,
                "message": message
            };

            // Send feedback to API
            ApiAuth().then((data) => {
                PostRecord(data['access'], feedbackApiURI.toString(), feedbackData).then((resp) => {
                    // Success
                    if (resp.id) {
                        setEmail(userData ? userData.email : '');
                        setMessage('');
                        setSent(true);
                    }
                });
            }).then((resp) => {
                setSendInProgress(false);
            });
        }

        evt.preventDefault();
    };


    return (
        <div className={'w-100 h-100'}>
            <div className="w-100 h-100 d-flex align-items-center" onClick={handleShow}>
                <img src={IcoFeedback} alt={'Send Feedback'}/> Send Feedback
            </div>

            <Modal centered={true} show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit} noValidate validated={validated}>
                    <Modal.Header closeButton>
                        <Modal.Title>Send Feedback</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {sent ? (
                            <Alert variant={'success'}>
                                Thank you for your feedback!
                            </Alert>
                        ) : ''}
                        <Form.Group controlId="validationCustom01">
                            <Form.Control
                                required
                                type="email"
                                placeholder="Email Address"
                                value={email}
                                disabled={sent}
                                onChange={e => setEmail(e.target.value)}
                            />
                            {/*<Form.Control.Feedback>Looks good!</Form.Control.Feedback>*/}
                        </Form.Group>

                        <Form.Group controlId="formFeedbackMessage">
                            <Form.Control
                                required
                                as="textarea"
                                placeholder="Leave product feedback or share your ideas. This is not a way to contact us as you will not receive a response."
                                value={message}
                                rows="8"
                                disabled={sent}
                                onChange={e => setMessage(e.target.value)}
                            />
                            {/*<Form.Control.Feedback>Looks good!</Form.Control.Feedback>*/}
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        {sendInProgress ? (
                            <Button variant="primary" type="submit" disabled>
                                <Spinner animation="border" role="status" size="sm">
                                    <span className="sr-only">Sending... </span>
                                </Spinner> Sending...
                            </Button>
                        ) : !sent ? (
                            <Button variant="primary" type="submit" onClick={handleSubmit}>
                                Send
                            </Button>
                        ) : null}
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
};


export const KeyboardShortcutsModal = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    };

    const handleShow = () => setShow(true);

    return (
        <div>
            <span className="d-flex align-items-center" onClick={handleShow}><FontAwesomeIcon
                icon={faKeyboard} className="mr-1"/> Keyboard Shortcuts</span>

            <Modal show={show} size={'xl'} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Keyboard Shortcuts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Playback</Card.Title>
                                    <Card.Text>
                                        <Table responsive>
                                            <tbody>
                                            <tr>
                                                <td>Play</td>
                                                <td>SPACE</td>
                                            </tr>
                                            <tr>
                                                <td>Seek Back</td>
                                                <td>LEFT ARROW</td>
                                            </tr>
                                            <tr>
                                                <td>Seek Forward</td>
                                                <td>RIGHT ARROW</td>
                                            </tr>
                                            <tr>
                                                <td>Volume Up</td>
                                                <td>UP ARROW</td>
                                            </tr>
                                            <tr>
                                                <td>Volume Down</td>
                                                <td>DOWN ARROW</td>
                                            </tr>
                                            <tr>
                                                <td>Mute Audio</td>
                                                <td>M</td>
                                            </tr>
                                            <tr>
                                                <td>Fullscreen</td>
                                                <td>F</td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Video Tagging</Card.Title>
                                    <Card.Text>
                                        <Table responsive>
                                            <tbody>
                                            <tr>
                                                <td>Face Off</td>
                                                <td>O</td>
                                            </tr>
                                            <tr>
                                                <td>Off Zone</td>
                                                <td>A</td>
                                            </tr>
                                            <tr>
                                                <td>Breakout</td>
                                                <td>K</td>
                                            </tr>
                                            <tr>
                                                <td>Shot</td>
                                                <td>Z</td>
                                            </tr>
                                            <tr>
                                                <td>Power Play</td>
                                                <td>L</td>
                                            </tr>
                                            <tr>
                                                <td>Strength</td>
                                                <td>T</td>
                                            </tr>
                                            <tr>
                                                <td>Check</td>
                                                <td>H</td>
                                            </tr>
                                            <tr>
                                                <td>Line</td>
                                                <td>S</td>
                                            </tr>
                                            <tr>
                                                <td>D Pair</td>
                                                <td>D</td>
                                            </tr>
                                            <tr>
                                                <td>GK</td>
                                                <td>G</td>
                                            </tr>
                                            <tr>
                                                <td>Coach</td>
                                                <td>Y</td>
                                            </tr>
                                            <tr>
                                                <td>Backcheck</td>
                                                <td>U</td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
