import {call, put, takeLatest} from 'redux-saga/effects'
import * as actions from './actions'
import * as states from './states'
import CognitoService from "./CognitoService";

// auth is stateless. Each call to a auth action resets all state
let defaultState = {
    info: {},
    error: {},
    isSignedIn: states.AUTH_UNKNOWN,
    isConfirmed: states.AUTH_UNKNOWN,
    hasSignedUp: states.AUTH_UNKNOWN,
    hasSentCode: states.AUTH_UNKNOWN,
    hasChangedPassword: states.AUTH_UNKNOWN,
    passwordResetRequired: states.AUTH_UNKNOWN
}

function* init(action) {
    yield put({
        type: actions.AUTH_SET_STATE,
        payload: {
            ...defaultState
        }
    })
}

function* getUser() {
    try {
        const cognitoAPI = new CognitoService();
        // let user = auth.config.getUser()
        let session = yield call(cognitoAPI.currentSession);
        let user = yield call(cognitoAPI.getUser);
        yield put({
            type: actions.AUTH_SET_STATE,
            payload: {
                ...defaultState,
                isSignedIn: states.AUTH_SUCCESS,
                isConfirmed: states.AUTH_SUCCESS,
                info: {
                    username: user.username,
                    user_id: user.user_id ? user.user_id : null,
                    organizations: user.organizations ? user.organizations : [],
                    ...session}
            }
        })
    } catch (e) {
        yield put({
            type: actions.AUTH_SET_STATE,
            payload: {
                ...defaultState,
                isSignedIn: states.AUTH_FAIL,
                error: e
            }
        })
    }
}

function* signUp(action) {
    try {
        const {username, password, firstName, lastName, email, phoneNumber, inviteCode} = action.payload;
        const cognitoAPI = new CognitoService();
        yield call(cognitoAPI.signUp, username, password, firstName, lastName, email, phoneNumber, inviteCode);
        yield put({
            type: actions.AUTH_SET_STATE,
            payload: {
                ...defaultState,
                hasSignedUp: states.AUTH_SUCCESS
            }
        });
    } catch (e) {
        if (e.code === 'UserNotConfirmedException') {
            yield put({
                type: actions.AUTH_SET_STATE,
                payload: {isConfirmed: states.AUTH_FAIL, error: e}
            })
        } else if (e.code === 'PasswordResetRequiredException') {
            yield put({
                type: actions.AUTH_SET_STATE,
                payload: {passwordResetRequired: states.AUTH_SUCCESS, error: e}
            })
        } else {
            yield put({
                type: actions.AUTH_SET_STATE,
                payload: {isConfirmed: states.AUTH_SUCCESS, error: e}
            })
        }
    }
}

function* signOut() {
    try {
        const cognitoAPI = new CognitoService();
        yield call(cognitoAPI.signOut)
        yield put({
            type: actions.AUTH_SET_STATE,
            payload: {isSignedIn: states.AUTH_FAIL}
        });
    } catch (e) {
        yield put({
            type: actions.AUTH_SET_STATE,
            payload: {error: e, isSignedIn: states.AUTH_FAIL}
        });
    }
}

function* signIn(action) {
    try {
        const {username, password, code} = action.payload
        const cognitoAPI = new CognitoService();
        if (code) {
            yield call(cognitoAPI.confirmSignUp, username, code);
        }
        const user = yield call(cognitoAPI.signIn, username, password);
        let session = yield call(cognitoAPI.currentSession);
        yield put({
            type: actions.AUTH_SET_STATE,
            payload: {
                isSignedIn: states.AUTH_SUCCESS,
                isConfirmed: states.AUTH_SUCCESS,
                info: {username: user.username, ...session}
            }
        })
    } catch (e) {
        if (e.code === 'UserNotConfirmedException') {
            yield put({
                type: actions.AUTH_SET_STATE,
                payload: {isConfirmed: states.AUTH_FAIL, error: e}
            })
        } else if (e.code === 'PasswordResetRequiredException') {
            yield put({
                type: actions.AUTH_SET_STATE,
                payload: {passwordResetRequired: states.AUTH_SUCCESS, error: e}
            })
        } else {
            yield put({
                type: actions.AUTH_SET_STATE,
                payload: {isConfirmed: states.AUTH_SUCCESS, error: e}
            })
        }
    }
}

function* forgotPassword(action) {
    try {
        const {username} = action.payload
        const cognitoAPI = new CognitoService();
        yield call(cognitoAPI.forgotPassword, username)
        yield put({
            type: actions.AUTH_SET_STATE,
            payload: {
                ...defaultState,
                hasSentCode: states.AUTH_SUCCESS
            }
        })
    } catch (e) {
        yield put({
            type: actions.AUTH_SET_STATE,
            payload: {
                ...defaultState,
                error: e,
                isSignedIn: states.AUTH_FAIL
            }
        })
    }
}

function* changePassword(action) {
    try {
        const {username, code, password} = action.payload
        const cognitoAPI = new CognitoService();
        yield call(cognitoAPI.forgotPasswordSubmit, username, code, password)
        yield put({
            type: actions.AUTH_SET_STATE,
            payload: {
                ...defaultState,
                hasChangedPassword: states.AUTH_SUCCESS
            }
        })
    } catch (e) {
        yield put({
            type: actions.AUTH_SET_STATE,
            payload: {
                ...defaultState,
                error: e,
                isSignedIn: states.AUTH_FAIL
            }
        })
    }
}

function* completeNewPassword(action) {
    try {
        const {username, password, newPassword} = action.payload
        const cognitoAPI = new CognitoService();
        yield call(cognitoAPI.completeNewPassword, username, password, newPassword)
        yield put({
            type: actions.AUTH_SET_STATE,
            payload: {
                ...defaultState,
                hasChangedPassword: states.AUTH_SUCCESS
            }
        })
    } catch (e) {
        yield put({
            type: actions.AUTH_SET_STATE,
            payload: {
                ...defaultState,
                error: e,
                isSignedIn: states.AUTH_FAIL
            }
        })
    }
}

export default function* sagas() {
    yield takeLatest(actions.AUTH_INIT, init)
    yield takeLatest(actions.AUTH_GET_USER, getUser)
    yield takeLatest(actions.AUTH_SIGN_UP, signUp)
    yield takeLatest(actions.AUTH_SIGN_IN, signIn)
    yield takeLatest(actions.AUTH_SIGN_OUT, signOut)
    yield takeLatest(actions.AUTH_FORGOT_PASSWORD, forgotPassword)
    yield takeLatest(actions.AUTH_CHANGE_PASSWORD, changePassword)
    yield takeLatest(actions.AUTH_COMPLETE_NEW_PASSWORD, completeNewPassword)
}
