import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-scroll";
import {Col, Container, Navbar, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {Logo_noText, Logo_Med, Logo_Lg} from "../../Assets/js/image";
import MenuTop from "../Menu/MenuTop";
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/header.scss';

const displayName = 'Header component';
const propTypes = {
    isSignedIn: PropTypes.string,
    signUpError: PropTypes.bool,
    signOut: PropTypes.func,
    signIn: PropTypes.func,
    signUp: PropTypes.func,
    auth: PropTypes.object,
    Landing: PropTypes.bool,
    userData: PropTypes.object,
    currentLeftMenuState: PropTypes.string,
    ToggleLeftMenu: PropTypes.func,
    SetCurrentLeftMenuState: PropTypes.func
};
const HeaderComponent = props => {
    const {userData, currentLeftMenuState, SetCurrentLeftMenuState} = props;
    const [account_id, setAccount_id] = useState(null);
    const [loggedIn, setLoggedIn] = useState(false);
    const [headerClassName, setHeaderClassName] = useState('landing-content-wrapper w-100');
    useEffect(() => {
        if (userData) {
            setAccount_id(userData.account);
            setLoggedIn(true);
            setHeaderClassName('content-wrapper w-100')
        }

    }, [userData]);
    const signOut = () => {
        props.signOut()
    };
    const {Landing} = props;
    return (
        <header>
            <Container fluid className={'top-line p-0 d-flex align-items-center'}>
                <Row className={headerClassName}>
                    <Col md={4} className={'p-0'}>
                        {loggedIn ? (
                            <Navbar.Brand href="/" className={'navbar-brand'}>
                                <FontAwesomeIcon icon={faBars} onClick={(e) => {
                                    e.preventDefault();
                                    SetCurrentLeftMenuState(currentLeftMenuState === 'expand' ? 'retract' : 'expand');
                                }} className={'left-menu-toggle float-left'}/>
                                <img src={Logo_noText}
                                     className={'logo'}
                                     srcSet={`${Logo_Med} 2x, ${Logo_Lg} 3x`}
                                     alt="AttentiveVision"/>
                                <span className={'logo-text'}>AttentiveVision</span>
                            </Navbar.Brand>
                        ) : (
                            Landing ?
                                <Navbar.Brand className={'navbar-brand d-flex align-items-center'}>
                                    <Link href="#" to="top">
                                        <img src={Logo_noText}
                                             className={'logo'}
                                             srcSet={`${Logo_Med} 2x, ${Logo_Lg} 3x`}
                                             alt="AttentiveVision"/>
                                        <span className={'logo-text'}>AttentiveVision</span>
                                    </Link>
                                </Navbar.Brand>
                                :
                                <Navbar.Brand href="/" className={'navbar-brand d-flex align-items-center'}>
                                    <img src={Logo_noText}
                                         className={'logo'}
                                         srcSet={`${Logo_Med} 2x, ${Logo_Lg} 3x`}
                                         alt="AttentiveVision"/>
                                    <span className={'logo-text'}>AttentiveVision</span>
                                </Navbar.Brand>
                        )}
                    </Col>
                    <Col md={8} className={'d-flex align-content-center justify-content-end pr-0'}>
                        <MenuTop {...props} Landing={Landing} signOut={signOut} accountId={account_id}/>
                    </Col>
                </Row>
            </Container>
        </header>
    )
};
HeaderComponent.displayName = displayName;
HeaderComponent.propTypes = propTypes;
export default HeaderComponent;
