import React, {useState} from 'react'
import './style/contact.css';
import {Authenticate as ApiAuth, PostRecord} from "../../utils/Api";
import Form from "react-bootstrap/Form";
import InputMask from 'react-input-mask';
import {Alert, Button, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";

const contactApiURI = process.env.REACT_APP_AV_API_URI + "clientcare/contact/";

export const ContactForm = () => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const [validated, setValidated] = useState(false);
    const [sent, setSent] = useState(false);
    const [sendInProgress, setSendInProgress] = useState(false);
    const [formVisible, setFormVisible] = useState(true);
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = (evt) => {
        const form = evt.currentTarget;
        if (form.checkValidity() === false) {
            evt.stopPropagation();
            return;
        }

        setValidated(true);

        if (validated) {
            setSendInProgress(true);
            let contactFormData = {
                "first_name": !userData ? first_name : userData.first_name ? userData.first_name : '',
                "last_name": !userData ? last_name : userData.last_name ? userData.last_name : '',
                "phone": !userData ? phone : userData.phone ? userData.phone : '',
                "email": !userData ? email : userData.email ? userData.email : '',
                "message": message
            };

            // Send feedback to API
            ApiAuth().then((data) => {
                PostRecord(data['access'], contactApiURI.toString(), contactFormData).then((resp) => {
                    // Success
                    if (resp.id) {
                        setFirstName("");
                        setLastName("");
                        setPhone("");
                        setEmail("");
                        setMessage("");
                        setSent(true);
                    }
                });
            }).then((resp) => {
                setSendInProgress(false);
                setFormVisible(false);
            });
        }

        evt.preventDefault();
    };

    return (
        <div>
            {sent ? (
                <Alert variant={'success'}>
                    Thank you for contacting AttentiveVision!
                </Alert>
            ) : ''}
            <Form id={'frmContact'} onSubmit={handleSubmit} style={{display: formVisible ? 'block' : 'none'}} noValidate validated={validated}>
                {!userData ? (
                    <div>
                        <Form.Group controlId="validationCustom01">
                            <Form.Label className={'float-left'}>First Name</Form.Label>
                            <Form.Control
                                required
                                type="first_name"
                                placeholder="Enter your first name"
                                defaultValue=""
                                disabled={sent ? true : false}
                                onChange={e => setFirstName(e.target.value)}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="validationCustom01">
                            <Form.Label className={'float-left'}>Last Name</Form.Label>
                            <Form.Control
                                required
                                type="last_name"
                                placeholder="Enter your last name"
                                defaultValue=""
                                disabled={sent ? true : false}
                                onChange={e => setLastName(e.target.value)}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="validationCustom01">
                            <Form.Label className={'float-left'}>Mobile No</Form.Label>
                            <InputMask name="phone" className={'form-control'} mask="+1 999 999 9999" maskChar="_"
                                       placeholder="Enter your mobile number"
                                       value={phone} onChange={e => setPhone(e.target.value)}
                                       required/>
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="validationCustom01">
                            <Form.Label className={'float-left'}>Email</Form.Label>
                            <Form.Control
                                required
                                type="email"
                                placeholder="Enter your email"
                                defaultValue=""
                                disabled={sent ? true : false}
                                onChange={e => setEmail(e.target.value)}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                    </div>
                ) : null}
                <Form.Group controlId="formFeedbackMessage">
                    <Form.Label className={'float-left'}>Message</Form.Label>
                    <Form.Control
                        required
                        as="textarea"
                        placeholder="Enter your comments here..."
                        defaultValue=""
                        rows="8"
                        disabled={sent ? true : false}
                        onChange={e => setMessage(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

                {!sent ?
                    sendInProgress ? (
                        <Button variant="primary" type="submit" className={'mt-3'} disabled>
                            <Spinner animation="border" role="status" size="sm">
                                <span className="sr-only">Sending... </span>
                            </Spinner> Sending...
                        </Button>
                    ) : (
                        <Button variant="primary" type="submit" className={'mt-3'} onClick={handleSubmit}>
                            Send <FontAwesomeIcon icon={faPaperPlane}/>
                        </Button>
                    )
                    : ''}
            </Form>
        </div>
    )
};

export const ContactComponent = () => {
    let mainWidth = '100%';
    if (document.getElementById("menuLeft")) {
        mainWidth = "calc(100% - " + document.getElementById("menuLeft").style.width + "px)";
    }

    return (
        <div id={'main'} className={'h-100'} style={{width: mainWidth}}>
            <div className="row mb-5" style={{minHeight: 'calc(100% - 267px)'}}>
                <div className="col-md-8 m-auto text-center">
                    <div className="contact-form-title-wrapper">
                        <p style={{textTransform: 'uppercase'}}>Got a question for us?</p>
                        <h1 className={'text-uppercase m-0'}>Get in Touch</h1>
                        <p>We’ll try and respond to your queries and suggestions as soon as we can.</p>
                    </div>
                    <div className="contact-form-wrapper">
                        <ContactForm/>
                    </div>
                </div>
            </div>
        </div>
    )
}
