import React from 'react';
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import AirHockey from "./FunStuff/AirHockey";

const displayName = '404 Not Found Page';
export default function NotFound(props) {
    return (
        <div id={'main'}>
            <div className={'text-center'}>
                <h1>404 Not Found</h1>
                <p>These are not the driods you're looking for.<br/>
                    Let's help you go about your business.</p>
            </div>
            <Row>
                <Col md={6}>
                    Here are some destinations you may want to visit:<br/>
                    <ul>
                        <li>
                            <Link to={'/videolist'}>Video List</Link>
                        </li>
                        <li>
                            <Link to={'/history'}>Event History</Link>
                        </li>
                        <li>
                            <Link to={'/teams'}>Teams</Link>
                        </li>
                        <li>
                            <Link to={'/stats'}>Stats</Link>
                        </li>
                        <li>
                            <Link to={'/contact'}>Contact AttentiveVision</Link>
                        </li>
                        <li>
                            <Link to={'/privacy'}>Privacy Policy</Link>
                        </li>
                        <li>
                            <Link to={'/terms'}>Terms and Conditions</Link>
                        </li>
                    </ul>
                </Col>
                <Col md={6}>
                    <p>In the meantime, want to play a game?</p>
                    <AirHockey/>
                </Col>
            </Row>
        </div>
    );
}
NotFound.displayName = displayName;
