// import ApiConfig from "../Config/ApiConfig";
// const username = ApiConfig.REACT_APP_AV_API_USERNAME;
// const password = ApiConfig.REACT_APP_AV_API_PASSWORD;
// const api_token_uri = ApiConfig.REACT_APP_AV_API_URI + "token/";
const username = process.env.REACT_APP_AV_API_USERNAME;
const password = process.env.REACT_APP_AV_API_PASSWORD;
const api_token_uri = process.env.REACT_APP_AV_API_URI + "token/";

export const Authenticate = async () =>
    await fetch(api_token_uri, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            username,
            password
        })
    }).then((resp) => {
        return resp.json();
    }).then((text) => {
        return text;
    });

export const GetRecord = async (token, url, data = null) =>
    await fetch(url.toString(), {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token
        }
    }).then((resp) => {
        return resp.json();
    }).then((text) => {
        return text;
    });

export const PostRecord = async (token, url, data = null) =>
    await fetch(url.toString(), {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token
        },
        // body: JSON.stringify({
        //     "video_id": "1",
        //     "marker_type": "faceOff",
        //     "name": "Face Off",
        //     "time_start": "00:50:00.000000",
        //     "date_recorded": "00:52:00.000000"
        // })
        body: JSON.stringify(data)
    }).then((resp) => {
        return resp.json();
    }).then((text) => {
        return text;
    });

export const PutRecord = async (token, url, data = null) =>
    await fetch(url.toString(), {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    }).then((resp) => {
        return resp.json();
    }).then((text) => {
        return text;
    });

export const RemoveRecord = async (token, url) =>
    await fetch(url.toString(), {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token
        }
    }).then((resp) => {
        return resp;
    });
