import React from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';

import {Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopyright} from "@fortawesome/free-solid-svg-icons";

import './style/footer.scss';

import ReactGA from 'react-ga';
import {Link} from "react-router-dom";
import moment from "moment";

ReactGA.initialize('UA-168213087-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export default class FooterComponent extends React.Component {
    static propTypes = {
        isSignedIn: PropTypes.string,
        signUpError: PropTypes.bool,
        signOut: PropTypes.func,
        signIn: PropTypes.func,
        signUp: PropTypes.func,
        auth: PropTypes.object,
        Landing: PropTypes.bool,
        username: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentWillUnmount() {
        this.setState((prevState, props) => {
            return {
                userData: {},
                user_id: null,
                date_created: null,
                email: null,
                first_name: null,
                last_name: null,
                plan_id: null,
                account_id: null,
                image: null
            };
        });
    }

    signOut = () => {
        this.props.signOut()
    };

    render() {
        const {username} = this.props;

        // Landing page has restricted width design
        let footerClassName = username ? 'content-wrapper' : 'landing-content-wrapper w-100';

        return (
            <footer>
                <Container fluid className={'p-0'}>
                    <Row className={footerClassName}>
                        <Col md={6} className={'footer-address'}>
                            <p>AttentiveVision<br/>
                            0002B Lassonde Building, York University<br/>
                            Toronto, ON, M3J 1P3</p>
                        </Col>
                        <Col md={6} className={'footer-copyright mt-auto'}>
                            <p>
                                <FontAwesomeIcon icon={faCopyright}/> Copyright 2020-{moment().format('YYYY')} AttentiveVision <br/>
                                <Link to="/privacy">Privacy Policy</Link> &nbsp;&bull;&nbsp; <Link to="/terms">Terms of Service</Link>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </footer>
        )
    }
}
