import React from 'react';

const homeRoutes = [
    {
        path: '/',
        exact:true,
        auth:false,
        component: React.lazy(() => import('../screens/Landing')),
    },
    {
        path: '/landing',
        exact:true,
        auth:false,
        component: React.lazy(() => import('../screens/Landing')),
    },
    {
        path: '/about',
        exact:true,
        auth:false,
        component: React.lazy(() => import('../pages/About')),
    },
    {
        path: '/press',
        exact:true,
        auth:false,
        component: React.lazy(() => import('../pages/Press')),
    },
    {
        path: '/copyright',
        exact:true,
        auth:false,
        component: React.lazy(() => import('../pages/Copyright')),
    },
    {
        path: '/terms',
        exact:true,
        auth:false,
        component: React.lazy(() => import('../pages/Terms')),
    },
    {
        path: '/privacy',
        exact:true,
        auth:false,
        component: React.lazy(() => import('../pages/Privacy')),
    },
    {
        path: '/contact',
        exact:true,
        auth:false,
        component: React.lazy(() => import('../screens/Contact')),
    },
    {
        path: '/eventlist',
        exact:true,
        auth:true,
        component: React.lazy(() => import('../screens/EventList')),
    },
    {
        path: '/watch/:organizationId/:videoId',
        exact:true,
        auth:true,
        component: React.lazy(() => import('../screens/Watch')),
    },
    {
        path: '/stats',
        exact:true,
        auth:true,
        component: React.lazy(() => import('../screens/Stats')),
    },
    {
        path: '/team',
        exact:true,
        auth:true,
        component: React.lazy(() => import('../screens/Team')),
    },
    {
        path: '/scoresheet',
        exact:true,
        auth:true,
        component: React.lazy(() => import('../screens/Scoresheet')),
    },
    {
        path: '/scoresheet/:scoresheetId',
        exact:true,
        auth:true,
        component: React.lazy(() => import('../screens/Scoresheet')),
    },
    {
        path: '/live',
        exact:true,
        auth:true,
        component: React.lazy(() => import('../screens/Live')),
    },
    // {
    //     path: '/library',
    //     exact:true,
    //     auth:true,
    //     component: React.lazy(() => import('../components/Library')),
    // },
    {
        path: '/history',
        exact:true,
        auth:true,
        component: React.lazy(() => import('../screens/History')),
    },
    {
        path: '/settings',
        exact:true,
        auth:true,
        component: React.lazy(() => import('../screens/Settings')),
    },
    // {
    //     path: '/profile',
    //     exact:true,
    //     auth:true,
    //     component: React.lazy(() => import('../components/Profile')),
    // },
    {
        path: '/answers/all',
        exact:true,
        auth:true,
        component: React.lazy(() => import('../screens/Answers')),
    },
    {
        path: '/answer/:articleId',
        exact:true,
        auth:true,
        component: React.lazy(() => import('../screens/Answers/AnswerArticleRead')),
    },
    {
        path: '/search/:searchText',
        exact:true,
        auth:true,
        component: React.lazy(() => import('../screens/Search')),
    },
];
export default homeRoutes;
