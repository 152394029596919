import {FormatPhone, StringToHex} from "../utils/Conversion";
import {Amplify, Auth} from "aws-amplify";
import config from '../aws-exports';
import {Authenticate as ApiAuth, GetRecord, PostRecord} from '../utils/Api';

Amplify.configure(config);

export default class CognitoService {
    signIn = async (username = '', password = '') => {
        let user;
        // Check if it's a phone number
        let phoneRe = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
        if (phoneRe.test(username.replace(/\D/g, ""))) {
            username = FormatPhone(username, true);
        }
        try {
            user = await Auth.signIn(username, password);
        } catch (error) {
            throw error;
        }
        return user;
    }
    confirmSignUp = async (username = '', code = '') => {
        let user;
        try {
            user = await Auth.confirmSignUp(username, code);
        } catch (error) {
            throw error;
        }
        return user;
    }
    completeNewPassword = async (username = '', password = '', newPassword = '') => {
        // NOT WORKING RIGHT NOW. NEED TO FIX
        debugger
        await Auth.completeNewPassword(username, newPassword)
            .then(user => {
                debugger
            })
            .catch(err => {
                debugger
            });
        debugger
        return true;
    }
    signOut = async () => {
        try {
            await Auth.signOut();
        } catch (error) {
            throw error;
        }
        return true;
    }
    currentSession = async () => {
        let session;
        try {
            session = await Auth.currentSession();
        } catch (error) {
            throw error;
        }
        return session;
    }
    signUp = async (username, password, firstName, lastName, email, phoneNumber, inviteCode) => {
        let ApiData;
        let phone_number = FormatPhone(phoneNumber, true);
        try {
            // Confirm user with username does not exist in your db
            ApiData = await ApiAuth();
            let userResp = await GetRecord(ApiData['access'], `${process.env.REACT_APP_AV_API_URI}users/${StringToHex(username)}`);
            if (userResp.id || userResp.length > 3) {
                throw Object.assign(
                    new Error('User already exists.'),
                    { code: 402 }
                );
            }
            // AWS Cognito
            if (!username || username === '')
                username = email;
            await Auth.signUp({
                username,
                password,
                attributes: {
                    email,
                    phone_number,
                }
            });
            // AV API
            let newUserData = {
                "first_name": firstName,
                "last_name": lastName,
                "email": email,
                "username": username,
                "phone_number": phoneNumber,
                "invite_code": inviteCode ? inviteCode : null,
            }
            ApiData = await ApiAuth();
            await PostRecord(ApiData['access'], `${process.env.REACT_APP_AV_API_URI}users/`, newUserData);
        } catch (error) {
            throw error;
        }
        return true;
    }
    forgotPassword = async (username = '') => {
        try {
            await Auth.forgotPassword(username);
        } catch (error) {
            throw error;
        }
        return true;
    }
    forgotPasswordSubmit = async (username, code, password) => {
        try {
            await Auth.forgotPasswordSubmit(username, code, password);
        } catch (error) {
            throw error;
        }
        return true;
    }
    getUser = async () => {
        let api, user, userAVDb;
        try {
            user = await Auth.currentUserInfo();
            // AV API
            api = await ApiAuth();
            userAVDb = await GetRecord(api['access'], `${process.env.REACT_APP_AV_API_URI}users/${StringToHex(user.username)}`);
            user.user_id = userAVDb.id;

            let respOrgOwner = await GetRecord(api.access, `${process.env.REACT_APP_AV_API_URI}organizations/?organization_owner=${userAVDb.id}`);
            let respOrgOwnerResults = [];
            respOrgOwner.results.map(value => {
                value.access_level = {
                    name: 'Owner',
                };
                respOrgOwnerResults.push(value);
                return true;
            })

            let promisesOrgMemberships = userAVDb.organization_memberships.map(function(value, index){
                return GetRecord(api.access, `${process.env.REACT_APP_AV_API_URI}organizations/${value.organization}/`).then(function(results){
                    results['access_level'] = {
                        name: value.access_level.name,
                        permissions: [],
                    };
                    value.access_level.access.map(value => {
                        results.access_level.permissions.push(value.action)
                        return true;
                    })
                    return results
                })
            })
            let respOrgMemberOf = await Promise.all(promisesOrgMemberships);

            user.organizations = [...respOrgOwnerResults, ...respOrgMemberOf];
        } catch (error) {
            throw error;
        }
        return user;
    }
}
