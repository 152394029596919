// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cognito_identity_pool_id": "ca-central-1:7a0509a9-4155-4647-9968-12d6562d6e24",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_0PuSKMTRK",
    "aws_user_pools_web_client_id": "3qreu3ushstg8eip0kev3kfbb3",
    "oauth": {}
};


export default awsmobile;
